@import "../core/cards";

// cards

@import "../core/cards/card-testimonials";
@import "../core/cards/card-profile";
@import "../core/cards/card-blog";
@import "../core/cards/card-product";
@import "../core/cards/card-pricing";
@import "../core/cards/card-collapse";
@import "../core/cards/card-signup";
@import "../core/cards/card-form-horizontal";
@import "../core/cards/card-background";

@import "../../../../node_modules/chartist/dist/scss/settings/chartist-settings";

// dashboard Cards

.card {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);

  .table tr:first-child td {
    border-top: none;
  }

  .card-title {
    margin-top: 0;
    margin-bottom: 3px;
  }

  .card-body {
    padding: $padding-card-body-y 20px;
    position: relative;

  }

  .card-header {
    z-index: 3 !important;

    .card-category {
      margin: 0;
    }

    &.card-header-text {
      display: inline-block;

      &:after {
        content: "";
        display: table;
      }
    }

    &.card-header-icon,
    &.card-header-text {
      i {
        width: 33px;
        height: 33px;
        text-align: center;
        line-height: 33px;
      }

      .card-title {
        margin-top: 15px;
        color: $black-color;
      }

      h4 {
        font-weight: 300;
      }
    }

    &.card-header-tabs {
      .nav-tabs {
        background: transparent;
        padding: 0;
      }

      .nav-tabs-title {
        float: left;
        padding: 10px 10px 10px 0;
        line-height: 24px;
      }
    }
  }

  &.card-plain {
    .card-header {

      &.card-header-icon+.card-body .card-title,
      &.card-header-icon+.card-body .card-category {
        margin-top: -20px;
      }
    }
  }

  .card-actions {
    position: absolute;
    z-index: 1;
    top: -50px;
    width: calc(100% - 30px);
    left: 17px;
    right: 17px;
    text-align: center;

    .card-header {
      padding: 0;
      min-height: 160px;
    }

    .btn {
      padding-left: 12px;
      padding-right: 12px;
    }

    .fix-broken-card {
      position: absolute;
      top: -65px;
    }
  }

  &.card-chart {
    .card-footer i:nth-child(1n+2) {
      width: 18px;
      text-align: center;
    }

    .card-category {
      margin: 0;
    }
  }

  .card-body+.card-footer,
  .card-footer {
    padding: 0;
    padding-top: 10px;
    margin: 0 15px 10px;
    border-radius: 0;
    justify-content: space-between;
    align-items: center;

    h6 {
      width: 100%;
    }

    .stats {
      color: #999999;
      font-size: 12px;
      line-height: 22px;

      .card-category {
        padding-top: 7px;
        padding-bottom: 7px;
        margin: 0;
      }

      .material-icons {
        position: relative;
        top: 4px;
        font-size: 16px;
      }
    }
  }

  [class*="card-header-"] {
    margin: 0px 15px 0;
    padding: 0;

    &:not(.card-header-icon):not(.card-header-text):not(.card-header-image) {
      border-radius: $border-radius-base;
      margin-top: -20px;
      padding: 15px;
    }

    .card-icon,
    .card-text {
      border-radius: $border-radius-base;
      background-color: $gray-color;
      padding: 15px;
      margin-top: -20px;
      margin-right: 15px;
      float: left;
    }

    .card-text {
      float: none;
      display: inline-block;
      margin-right: 0;

      .card-title {
        color: $white-color;
        margin-top: 0;
      }
    }

    position: relative;

    .ct-chart {
      .card-title {
        color: $white-color;
      }

      .card-category {
        margin-bottom: 0;
        color: rgba($white-color, .62);
      }

      .ct-label {
        //color: rgba($white-color, .7);
      }

      .ct-grid {
        stroke: rgba(255, 255, 255, 0.2);
      }



      .ct-series-a .ct-bar {
        stroke-width: 10px;
      }

      .ct-point {
        stroke-width: 10px;
        stroke-linecap: round;
      }

      .ct-line {
        fill: none;
        stroke-width: 4px;
      }
    }
  }


  .chart {
    .card-footer {
      color: #000;
    }
  }


  [data-header-animation="true"] {
    @include transform-translate-y(0);
    -webkit-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
    -moz-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
    -o-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
    -ms-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
    transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
  }

  &:hover {
    [data-header-animation="true"] {
      @include transform-translate-y(-50px);
    }
  }

  &.card-stats {
    .card-header {

      &.card-header-icon,
      &.card-header-text {
        text-align: right;
      }

      .card-icon+.card-title,
      .card-icon+.card-category {
        padding-top: 10px;
      }

      .card-title,
      .card-category {
        margin: 0;
      }

      .card-category {
        margin-bottom: 0;
        margin-top: 0;

        &:not([class*="text-"]) {
          color: $gray-color;
          font-size: $font-paragraph;
        }
      }

      &+.card-footer {
        border-top: 1px solid #eee;
        margin-top: 20px;
      }

      i {
        font-size: 36px;
        line-height: 56px;
        width: 56px;
        height: 56px;
        text-align: center;
      }
    }

    .card-body {
      text-align: right;
    }
  }



  .map {
    height: 280px;
    border-radius: $border-radius-large;
    margin-top: 15px;

    &.map-big {
      height: 420px;
    }
  }

  .card-body.table-full-width {
    padding: 0;
  }

  .card-plain .card-header-icon {
    margin-right: 15px !important;
  }
}

.table-sales {
  margin-top: 40px;
}

.iframe-container {
  margin: 0 -20px 0;

  iframe {
    width: 100%;
    height: 500px;
    border: 0;
    @include shadow-big();
  }
}

.card-wizard {
  .nav.nav-pills {
    .nav-item {
      margin: 0;

      .nav-link {
        padding: 6px 15px !important;
      }
    }
  }

  .mat-form-field {
    width: 80%;
    font-size: 14px;
    top: 3px;
  }

  .nav-pills:not(.flex-column) .nav-item+.nav-item:not(:first-child) {
    margin-left: 0;
  }

  .nav-item .nav-link.active,
  .nav-item .nav-link:hover,
  .nav-item .nav-link:focus {
    background-color: inherit !important;
    box-shadow: none !important;
  }

  .input-group-text {
    padding: 6px 15px 0px !important;
  }

  .card-footer {
    border-top: none !important;
  }
}

.card-chart,
.card-product {
  .card-body+.card-footer {
    border-top: 1px solid #eee;
  }
}

.card-product {
  .price {
    color: inherit;
  }
}

.card-collapse {
  margin-bottom: 15px;

  .card .card-header a[aria-expanded="true"] {
    color: #e91e63;
  }
}

.chart .ct-chart-bar.ct-horizontal-bars {
  margin-top: 10px;
}

.legend .ct-chart div>span {
  font-size: x-small;
}
